import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const isLarge = width >= 1440;
  const isMedium = width >= 744 && width < 1440;
  const isSmall = width < 744;

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { isLarge, isMedium, isSmall };
};

export default useScreenSize;
