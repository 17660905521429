import { useRouter } from 'next/router';
import CONFIG from '../config';

export const useShowFlexibleComponents = () => {
  const router = useRouter();
  const { allowedRoutesForFlexiblePages } = CONFIG;

  const isShowFlexibleComponents = allowedRoutesForFlexiblePages.includes(
    router.asPath
  );
  return { isShowFlexibleComponents };
};
